// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

//to do: add error border style to ckeditor5

.is-invalid .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: red;
}

// .is-valid .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
//   border-color: green;
// }
