$color-orange: #ef7d30;
$color-dark-orange: #ff6600;
$color-light-orange: #ffc8a2;
$color-lighter-orange: #ffdfc9;
$color-pink: #fce6d7;
$color-light-pink: #fff0e6;
$color-dark-primary: #002333;

.opacity-100 {
  opacity: 1 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.bg-hover-orange:hover {
  background-color: $color-orange;
}

.bg-hover-danger:hover {
  background-color: var(--danger) !important;
}

.bg-hover-warning:hover {
  background-color: var(--warning) !important;
}

.bg-black {
  background-color: black;
}

.bg-dark-primary {
  background-color: $color-dark-primary;
}

.bg-orange {
  background-color: $color-orange;
}

.bg-dark-orange {
  background-color: $color-dark-orange;
}

.bg-light-orange {
  background-color: $color-light-orange;
}

.bg-lighter-orange {
  background-color: $color-lighter-orange;
}

.bg-light-pink {
  background-color: $color-light-pink;
  color: black;
}

.bg-pink {
  background-color: $color-pink;
  color: black;
}

.text-dark-orange {
  color: $color-dark-orange;
}

.text-orange {
  color: $color-orange;
}

.text-black {
  color: black;
}

.text-info {
  color: #39f
}

.text-hover-success:hover {
  color: var(--success) !important;
}

.text-hover-danger:hover {
  color: var(--danger) !important;
}

.text-hover-orange:hover {
  color: $color-orange !important;
}

.text-o-ellipsis {
  text-overflow: ellipsis;
}

.text-line-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-line-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-line-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-line-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.text-decoration-0 {
  text-decoration: none !important;
}

.fw-900 {
  font-weight: 900;
}

.fw-800 {
  font-weight: 800;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.lh-70 {
  line-height: 70px;
}

.lh-40 {
  line-height: 40px;
}

.lh-30 {
  line-height: 30px;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.img-flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.objectfit-cover {
  object-fit: cover;
}

.objectfit-contain {
  object-fit: contain;
}

@for $i from 1 through 10 {
  .z-index-#{$i * 100} {
    z-index: $i * 100;
  }
}

.interact-none {
  pointer-events: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-shadow-orange:hover {
  box-shadow: 0 0.5rem 0.5rem $color-orange !important;
}

.hover-shadow-light-orange:hover {
  box-shadow: 0 0.5rem 0.5rem $color-light-orange !important;
}

.border-orange {
  border-color: $color-orange !important;
}

$border-sizes: 3px, 5px, 8px, 10px, 15px, 18px, 20px, 25px, 35px;

@each $size in $border-sizes {
  .rounded-#{$size} {
    border-radius: $size;
  }
}

$wh-sizes: 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 150, 200;

@each $size in $wh-sizes {
  .w-#{$size}px {
    width: $size * 1px !important;
  }

  .h-#{$size}px {
    height: $size * 1px !important;
  }

  .w-#{$size}pc {
    width: $size * 1% !important;
  }

  .h-#{$size}pc {
    height: $size * 1% !important;
  }

  .w-#{$size}vh {
    width: $size * 1vh !important;
  }

  .h-#{$size}vh {
    height: $size * 1vh !important;
  }
}
