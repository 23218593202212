.checkout-info {
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    box-sizing: inherit;
    margin: 0;
    position: relative;
    list-style: none;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border-radius: 5px;
}

.checkout-info-item{
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    text-decoration: none;
    display: flex;
    padding: 2% 5% 2% 0%;
    font-size: 18px;
    margin-left: 0px;
    justify-content: space-between;
}


.checkout-info-label-left {
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    text-align: left;
    box-sizing: inherit;
    max-width: 180px;
}

.checkout-info-separator{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    box-sizing: inherit;
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    opacity: 0.1;
    background-color: #505050;
}

.checkout-info-result {
    -webkit-font-smoothing: antialiased;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    text-decoration: none;
    display: flex;
    padding: 5% 5% 5% 0%;
    font-weight: bold;
    margin-left: 0px;
    justify-content: space-between;
}

.jss28 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    box-sizing: inherit;
    margin-top: 50px;
}

.jss29 {
    -webkit-font-smoothing: antialiased;
    visibility: visible;
    box-sizing: inherit;
    color: #202020;
    font-size: 20px;
    font-style: normal;
    font-family: SF Pro Display;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-transform: capitalize;
}

.jss30 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    box-sizing: inherit;
    margin-top: 35px;
}

.jss298 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    box-sizing: inherit;
    margin: 0;
    position: relative;
    list-style: none;
    padding: 0;
    padding-top: 18px;
}

.jss279 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    box-sizing: inherit;
    display: flex;
    align-items: center;
}

.jss280 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    padding-right: 16px;
    cursor: pointer;
    padding-left: 0px;
    align-items: end;
    padding-top: 0;
}

.jss300 {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    visibility: visible;
    list-style: none;
    text-align: left;
    cursor: pointer;
    box-sizing: inherit;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: SF Pro Display;
    line-height: 1;
    user-select: none;
    justify-content: center;
    color: #1d1e22;
    width: 30px;
    height: 25px;
    background-color: transparent;
    border-radius: 0px;
}

.jss301 {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    visibility: visible;
    list-style: none;
    text-align: left;
    cursor: pointer;
    font-size: 1.25rem;
    font-family: SF Pro Display;
    line-height: 1;
    user-select: none;
    color: #1d1e22;
    box-sizing: inherit;
    width: 100%;
    height: 100%;
}

.jss281 {
    -webkit-font-smoothing: antialiased;
    font-family: SF Pro Display;
    font-weight: 400;
    visibility: visible;
    list-style: none;
    text-align: left;
    cursor: pointer;
    box-sizing: inherit;
    margin: 0;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.jss282 {
    -webkit-font-smoothing: antialiased;
    color: #222222;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    box-sizing: inherit;
    border: none;
    height: 1px;
    flex-shrink: 0;
    opacity: 0.1;
    background-color: #494949;
    margin: 0px 0px 0px 55px;
}

.jss283 {
    -webkit-font-smoothing: antialiased;
    visibility: visible;
    list-style: none;
    text-align: left;
    cursor: pointer;
    box-sizing: inherit;
    margin: 0;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    opacity: 0.5;
}

.jss284 {
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    box-sizing: inherit;
    top: unset;
    right: unset;
    cursor: pointer;
    position: initial;
    transform: unset;
    margin-right: 16px;
}

.MuiSvgIcon-root {
    -webkit-font-smoothing: antialiased;
    font-family: SF Pro Display;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    cursor: pointer;
    box-sizing: inherit;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.jss285 {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.43;
    visibility: visible;
    list-style: none;
    cursor: pointer;
    box-sizing: inherit;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    // color: #f00bfc;
}

.checkout-info-label-right {
    max-width: 70%;
    text-align: right;
}

.checkout-info-label-right:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.checkout-info-label-bold {
    font-weight: bold;
}

.checkout-info-label-or {
    width: 100%;
    font-size: 18px;
    text-align: center;
    color: var(--primary-color);
}

.checkout-separator {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    background-color: #ccc;
}

.App_wrapper .Header_btn {
    border-radius: 36px;
    min-width: 180px;
    height: 42px;
    font-size: 1rem;
    text-transform: uppercase;
    background: linear-gradient(#f00bfc, #5885e7);
}

.checkout-note {
    color: #a27f00;
}